//timesheet

export const APPROVED_PRODUCT_LIST = 'APPROVED_PRODUCT_LIST'
export const TASK_LIST = 'TASK_LIST'
export const EXISTING_DATES = 'EXISTING_DATES'
export const YESTERDAY_COUNT = 'YESTERDAY_COUNT'
export const PIECHART_IMG = 'PIECHART_IMG'
export const ATTENDANCE_SHEET = 'ATTENDANCE_SHEET'
export const SET_PENDING_DATA = 'SET_PENDING_DATA'
// submitted Activity
export const EDIT_SUBMITED_ACTIVITY = 'EDIT_SUBMITED_ACTIVITY'
export const EDIT_SUBMITTED_ACTIVITY_SUCCESS = 'EDIT_SUBMITED_ACTIVITY'
export const EDIT_SUBMITTED_ACTIVITY_FAILURE = 'EDIT_SUBMITED_ACTIVITY'
export const GET_SUBMITTED_TIMESHEET = 'GET_SUBMITTED_TIMESHEET'
export const GET_RAISED_REQUEST = 'GET_RAISED_REQUEST'
export const GET_LEAVE_HISTORY = 'GET_LEAVE_HISTORY'
export const WITHDRAW_LEAVE_SUCCESS = 'WITHDRAW_LEAVE_SUCCESS'
export const WITHDRAW_LEAVE_FAILURE = 'WITHDRAW_LEAVE_FAILURE'

//dashboard
export const FLOW_COUNT_DATA = 'FLOW_COUNT_DATA'
export const DESIGNATION_MEMBERS = 'DESIGNATION_MEMBERS'
export const MEMBERS_ALL = 'MEMBERS_ALL'
export const SEQUENCE_LIST = 'SEQUENCE_LIST'
export const PRODUCT_BY_HEAD = 'PRODUCT_BY_HEAD'
export const PRODUCT_ASSIGNED = 'PRODUCT_ASSIGNED'
export const FLOW_ACCESS = 'FLOW_ACCESS'
export const ASSIGNED_BY_OWNER = 'ASSIGNED_BY_OWNER'
export const MEMBERS_ACTIVITY_COUNT = 'MEMBERS_ACTIVITY_COUNT'
export const MEMBERS_ACTIVITY_SEC_LVL_COUNT = 'MEMBERS_ACTIVITY_SEC_LVL_COUNT'
export const TEAM_MEMBERS_COUNT = 'TEAM_MEMBERS_COUNT'
export const MY_ACTIVITY_COUNT = 'MY_ACTIVITY_COUNT'
export const MY_TIMESHEET_DETAILS = 'MY_TIMESHEET_DETAILS'
export const TIMESHEET_STATUS_ROLE_BASED = 'TIMESHEET_STATUS_ROLE_BASED'
export const TIMESHEET_STATUS_CONTRACT = 'TIMESHEET_STATUS_CONTRACT'
export const CONTRACT_AND_SUPERVISOR = 'CONTRACT_AND_SUPERVISOR'
export const WEEKLY_TIMESHEET_HOURS = 'WEEKLY_TIMESHEET_HOURS'
export const UPDATED_SEQUENCE = 'UPDATED_SEQUENCE'
export const MASTERS_COUNT = 'MASTERS_COUNT'
export const COUNT_SIDEBAR = 'COUNT_SIDEBAR'

//  side bar
export const SIDE_BAR_SHOW = 'SIDE_BAR_SHOW'
export const PRODUCT_COUNT_BY_APPROVER = 'PRODUCT_COUNT_BY_APPROVER'
export const TIMESHEET_DAYS_COUNT = 'TIMESHEET_DAYS_COUNT'

//  contract Member
export const GET_CONTRACT_MEMBER = 'GET_CONTRACT_MEMBER'

// Member Activity
export const GET_MEMBER_ACTIVITY = 'GET_MEMBER_ACTIVITY'
// Define action types
export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS'
export const STATUS_CHANGE_FAILURE = 'STATUS_CHANGE_FAILURE'

// Contract Member Activity
export const GET_CONTRACT_MEMBER_ACTIVITY = 'GET_CONTRACT_MEMBER_ACTIVITY'
export const GET_CONTRACT_CLOSED_MEMBER_ACTIVITY = 'GET_CONTRACT_CLOSED_MEMBER_ACTIVITY'

// Owner Member Activity
export const GET_OWNER_MEMBER_ACTIVITY = 'GET_OWNER_MEMBER_ACTIVITY'
export const GET_PRODUCT_MEMBER_CLOSED_ACTIVITY = 'GET_PRODUCT_MEMBER_CLOSED_ACTIVITY'

//profile-pic
export const GET_PROFILE_PIC = 'GET_PROFILE_PIC'
